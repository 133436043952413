<template>
  <div class="alarm">
    <a-row class="form">
      <a-col  class="form-item">
        <span class="label"> 预警搜索 </span>
        <a-select default-value="0" style="width: 120px; margin-right: 8px;">
          <a-select-option v-for="(item, key) in alarmTypeOption"
                           :key="key"
                           :label="item.label"
                           :value="item.value + ''">{{item.label}}</a-select-option>
        </a-select>
        <a-input style="width: 360px"></a-input>
      </a-col>
      <a-col class="form-item">
        <span class="label">查询时间  </span>
        <a-range-picker
          class="form-item-select"
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始时间', '结束时间']"
        />

        <span class="form-item-option active">今日</span>
        <span class="form-item-option">昨天</span>
        <span class="form-item-option">最近7天</span>
        <span class="form-item-option">最近30天</span>
      </a-col>

      <a-col class="form-item form-btn">
        <a-button type="primary">筛选</a-button>
        <a-button style="margin: 0 8px;">重置</a-button>
        <span class="download"><my-icon type="iconwenjianxiazai"></my-icon>下载数据</span>
      </a-col>
    </a-row>
    <a-table
      class="material-list"
      :columns="columns"
      :row-key="record => record.uuid"
      :data-source="alarms"
      :pagination="pagination"
      :loading="loading"

      :scroll="{ x: '100%'}"
    >
      <div slot="name" slot-scope="device">
        <p> {{ device.deviceUuid }}</p>
        <p> {{ device.deviceName }}</p>
      </div>
    </a-table>
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        alarmTypeOption: [
          {label: '设备名称', value: 0},
        ],
        alarms: [],
        loading: false,
        columns: [
          {
            title: '设备名称',
            fixed: 'left',
            scopedSlots: {customRender: 'name'},
            width: 160,
          },
          {
            title: '预警名称',
            width: 108,
            scopedSlots: {customRender: 'onlineStatus'},
          },
          {
            title: '开始时间',
            width: 120,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '结束时间',
            width: 120,
            scopedSlots: {customRender: 'department'},
          },
          {
            title: '通知时间',
            width: 120,
            scopedSlots: {customRender: 'useLevel'},
          },
        ],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .alarm {
    .form {
      width: 680px;
      margin: auto;

      &-btn{
        padding-left: 72px;
      }

      &-item {
        margin-bottom: $middle-space;

        &-select {
          margin-right: $middle-space;
        }

        &-option {
          display: inline-block;
          margin-right: $middle-space;
          cursor: pointer;
        }

        .active{
          color: $theme-color;
        }

        &-option:hover{
          color: $theme-color;
        }

      }
    }

    .label {
      display: inline-block;
      margin: 0 $small-space;
      @include font-little-h();
    }

    .download{
      margin-left: $small-space;
      @include font-little-h();
      color: $theme-color;
      cursor: pointer;
    }

    &-list{
      padding: 0 $container-padding;
    }

    &-list  .ant-table {
      min-height: 200px !important;
    }

    &-list  .ant-empty-normal {
      margin: 100px 0 !important;
    }
  }
</style>
